/* You can add global styles to this file, and also import other style files */
* {
    scroll-behavior: smooth;
}
html {
    font-size: 62.5%;
}
img {
    max-width: 100%;
}
a {
    text-decoration: none !important;
    color: #000 !important;
}

.custom-popover {
    --bs-popover-max-width: 900px;
    .popover {
        border: none;
    }
    background-color: #fff;
    .header {
        margin-top: 1rem;
        border-bottom: 1px solid #ccc;
        text-align: center;
    }
    .body {
        padding: 2rem 1rem;
        h4 {
            text-align: center;
            color: #666666;
            font-weight: 400;
        }
    }
}
.underline {
    background-color: #67bcdd !important;
    height: 2px;
    width: 70px;
    margin-left: auto;
    margin-right: auto;
}

.captcha {
    padding-top: 2rem !important;
    color: #4b5669 !important;
    font-size: 0.8rem !important;
}
.general-title-separation {
    margin-top: 100px;
    margin-bottom: 50px;
}
.pt-100 {
    padding-top: 100px;
}
.general-input {
    width: auto;
    height: 46px;
    border: 0;
    background-color: #eff8fb;
    border-radius: 0;
    font-weight: 500;
    font-size: 16px;
    resize: none;
    padding: 1rem;
    margin-bottom: 10px;
    &::placeholder {
        font-weight: 600;
    }
    &:focus-visible {
        outline: none;
    }
}
/* Importing Bootstrap SCSS file. */
@import "./node_modules/bootstrap/scss/bootstrap";

/* Importing Datepicker SCSS file. */
@import "node_modules/ngx-bootstrap/datepicker/bs-datepicker";
.modal.show .modal-dialog {
    transform: none;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 20px;
    padding-right: 20px;
}
.modal-dialog {
    max-width: 700px;
}
select {
    appearance: none;
    -webkit-appearance: none;
}

.bot {
    color: white;
    border-color: white;
}
.bot:hover {
    background-color: white !important;
    color: black !important;
}
.bot-bck {
    color: #000 !important;
    background-color: white !important;
}
.bot-bck:hover {
    background-color: black !important;
    color: white !important;
}

/*--------RECAPTCHA--------*/
.grecaptcha-badge {
    visibility: hidden;
}

/*-------------------------*/

.modal-cookie-banner {
    bottom: 0 !important;
    transform: none !important;
    width: 100% !important;
    max-width: 100% !important;
    padding: 0 0 !important;
    position: absolute !important;
    top: auto !important;
    margin: 0 !important;
}

.scroll-down {
    p {
        span {
            cursor: pointer !important;
            color: white !important;
            font-weight: 400 !important;

            i {
                margin-right: 2rem !important;
                font-size: 2rem !important;
                color: white !important;
            }
        }
        position: absolute !important;
        width: 100% !important;
        bottom: 2rem !important;
        font-size: 2rem !important;
        text-align: center !important;
    }
}

.pin-overlay {
    width: 250px;
    padding: 7px 0 0 0;
    background: rgba(247, 247, 247, 0.75);
    border-radius: 5px;
    box-shadow: 10px 10px 50px rgba(0, 0, 0, 0.29);
    font-family: Helvetica, Arial, sans-serif;
    transform-origin: 0 10px;
    h1 {
        margin-top: 0;
        padding: 5px 15px;
        /* background: #2aaef5; */
        color: #edd000;
        font-size: 16px;
        font-weight: 700;
    }
    section {
        padding: 0 15px 5px;
        font-size: 14px;
        p {
            margin: 5px 0;
            &:first-child {
                font-weight: 300;
            }
        }
        a {
            color: #67bcdd !important;
            text-decoration: underline !important;
        }
    }
    &::after {
        content: "";
        position: absolute;
        top: 7px;
        left: -12px;
        width: 0;
        height: 29px;
        margin-bottom: -13px;
        border-right: 13px solid rgba(247, 247, 247, 0.75);
        border-top: 13px solid rgba(0, 0, 0, 0);
        border-bottom: 13px solid rgba(0, 0, 0, 0);
    }
}

.pdfViewer.singlePageView .page {
    margin: 0;
}